<template>
  <div class="container">
    <b-table
      ref="table"
      class="lms-table manage-students manage-students--no-border scroll"
      id="table-manage-admins"
      :items="users"
      :fields="fields()"
      primary-key="username"
      borderless
      :tbody-transition-props="{ name: 'flip-list' }"
      stacked="sm"
      :no-local-sorting="true"
      :fixed="false"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :no-provider-paging="true"
      :current-page="currentPage"
      :show-empty="true"
      no-footer-sorting
      no-sort-reset
    >
      <template #thead-top="">
        <b-tr>
          <b-th colspan="6">
            <b-tr v-if="!isManagerRole" class="manage-students__upper-header">
              <div class="header-item header-item--active" @click="toggleSelectAll">
                <RadioButton
                  :checked="allSelected"
                  :isTypeCheckBox="true"
                  size="sm"
                  @change="toggleSelectAll"
                />

                <span> {{ $t('label.selectAll') }}</span>
              </div>
              <div
                class="header-item"
                :class="{ 'header-item--active': selected.length }"
                @click="groupCloseAccess"
              >
                <RedCrossCircleIcon :class="{ 'inactive-icon': !selected.length }" />
                <span>{{ $t('label.closeAccessToPrograms') }}</span>
              </div>
              <div
                class="header-item"
                :class="{ 'header-item--active': selected.length }"
                @click="groupOpenAccess"
              >
                <GreenFlagCircleIcon :class="{ 'inactive-icon': !selected.length }" />
                <span>{{ $t('label.openAccessToPrograms') }} </span>
              </div>
            </b-tr>
          </b-th>
        </b-tr>
      </template>

      <template #cell(selectUser)="data" class="select-user">
        <RadioButton
          :checked="isUserSelected(data.item._id)"
          :isTypeCheckBox="true"
          size="sm"
          @change="selectUser(data.item._id)"
        />
      </template>

      <template #cell(full_name)="data">
        <div :class="{ 'td-inactive': isBanned(data) }" @click="selectUser(data.item._id)">
          {{ data.item.firstName }} {{ data.item.lastName }}
        </div>
      </template>

      <template #cell(username)="data">
        <div :class="{ 'td-inactive': isBanned(data) }">
          {{ data.item.username }}
        </div>
      </template>

      <template #cell(status)="data">
        <div :class="{ 'td-inactive': isBanned(data) }" class="d-flex">
          <StatusDropDown :disabled="true" :status="data.item.status" @changeStatus="(status) =>
        changeUserStatus(status, data)"/>
        </div>
      </template>

      <template #cell(access)="data">
        <LmsSwitch
          :value="!data.item.ban_status"
          @update:value="(bool) => toggleUserAccess(bool, data.item._id)"
          :with-icon="true"
        />
      </template>

      <template #custom-foot>
        <tr role="row" ref="footer">
          <th colspan="8" class="table-footer" :class="{'table-footer--invisible':!loading}">
        <tr >
          <td >{{ $t('label.loading') }}</td>
        </tr>
        </th>
        </tr>
      </template>

      <template #empty>
        <div v-if="!loading" class="table-empty">
          <div class="table-empty__title">
            <p>{{ $t('supportText.youHaventInvitedAdminsYet') }}
            </p>
          </div>
          <div class="table-empty__subtitle">
            <p>{{ $t('supportText.noInvitedAdmins') }}</p>
          </div>
          <div class="table-empty__invite-button">
            <div class="invite-button" @click="showModalInviteAdmin"><PlusIcon/> {{
                $t('buttonLabels.invite') }}</div>
          </div>
          <div class="table-empty__image-container">
            <div class="image_wrapper">
              <div class="image">
                <img src="@/assets/images/svg/user.svg" alt="Пользователь" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import LmsSwitch from '@/components/LmsSwitch.vue';
import ActivityService from '@/services/activity.service';
import RadioButton from '@/components/Buttons/RadioButton.vue';
import RedCrossCircleIcon from '@/components/Icons/RedCrossCircleIcon.vue';
import GreenFlagCircleIcon from '@/components/Icons/GreenFlagCircleIcon.vue';
import StatusDropDown from '@/components/Statistics/StatusDropDown.vue';
import PlusIcon from '@/components/Icons/PlusIcon.vue';

export default {
  name: 'StatisticsAdminTable',
  components: {
    PlusIcon,
    StatusDropDown,
    LmsSwitch,
    RadioButton,
    RedCrossCircleIcon,
    GreenFlagCircleIcon,
  },
  props: {
    managerBanList: {
      type: Array,
      required: true,
    },
    showModalInviteAdmin: {
      type: Function,
      default: () => {},
    },
    isManagerRole: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    await this.getUsers();
  },
  data() {
    return {
      sortBy: 'username',
      sortDesc: false,
      currentPage: 1,
      perPage: 999,
      selected: [],
      allSelected: false,
      users: [],
      isRequestBusy: false,
      loading: true,
      programId: this.$route.params.programId,
    };
  },
  methods: {
    async getUsers() {
      await ActivityService.getProgramManagers({ programId: this.programId, id: this.programId })
        .then(({ data }) => {
          this.users = data.map((user) => {
            // eslint-disable-next-line no-param-reassign
            user.status = 'admin';
            // eslint-disable-next-line no-param-reassign
            user.ban_status = !!this.managerBanList.includes(user._id);
            return user;
          });
        })
        .catch(console.log);
      this.loading = false;
    },
    toggleSelectAll() {
      if (this.selected.length === this.allSelectedUsersId.length) {
        this.selected = [];
      } else {
        this.selected = this.allSelectedUsersId;
      }
    },
    toggleUserAccess(bool, userId) {
      if (this.isRequestBusy) {
        return;
      }
      const prevState = [...this.users];
      this.rerenderBanStatus(bool, userId);
      if (bool) {
        ActivityService.unbanManagersInProgram({
          programId: this.programId,
          id: this.programId,
          managers_ids: [userId],
        })
          .catch(() => (this.users = prevState));
      } else {
        ActivityService.banManagersInProgram({
          programId: this.programId,
          id: this.programId,
          managers_ids: [userId],
        })
          .catch(() => (this.users = prevState));
      }
    },

    async groupCloseAccess() {
      if (!this.selected.length) {
        return;
      }
      const onlyNotBannedIds = this.filterSelectedUsers(false);
      if (!onlyNotBannedIds.length) {
        return (this.selected = []);
      }

      const prevState = [...this.users];
      this.users = this.users.map((u) => {
        const clone = { ...u };
        if (onlyNotBannedIds.includes(clone._id)) {
          clone.ban_status = true;
        }
        return clone;
      });

      this.selected = [];
      this.isRequestBusy = true;
      await ActivityService.banManagersInProgram({
        programId: this.programId,
        id: this.programId,
        managers_ids:
        onlyNotBannedIds,
      }).catch(
        () => (this.users = prevState),
      );
      this.isRequestBusy = false;
    },
    async groupOpenAccess() {
      if (!this.selected.length) {
        return;
      }
      const onlyBannedIds = this.filterSelectedUsers(true);
      if (!onlyBannedIds.length) {
        return (this.selected = []);
      }

      const prevState = [...this.users];
      this.users = this.users.map((u) => {
        const clone = { ...u };
        if (onlyBannedIds.includes(clone._id)) {
          clone.ban_status = false;
        }
        return clone;
      });

      this.selected = [];
      this.isRequestBusy = true;
      await ActivityService.unbanManagersInProgram({
        programId: this.programId,
        id: this.programId,
        managers_ids:
        onlyBannedIds,
      }).catch(
        () => (this.users = prevState),
      );
      this.isRequestBusy = false;
    },

    selectUser(userId) {
      if (this.selected.includes(userId)) {
        this.selected = this.selected.filter((id) => id !== userId);
      } else {
        this.selected.push(userId);
      }
    },
    fields() {
      const f = [];
      if (!this.isManagerRole) {
        f.push({ key: 'selectUser', label: '', class: 'first-column' });
      }
      f.push({ key: 'full_name', label: this.$t('label.user'), class: 'user-name-column' });
      f.push({ key: 'username', label: 'E-mail', class: 'email-column' });
      f.push({ key: 'status', label: this.$t('label.status'), class: 'status-column' });
      if (!this.isManagerRole) {
        f.push({ key: 'access', label: this.$t('label.access'), class: 'access-column' });
      }
      return f;
    },
    rerenderBanStatus(bool, userId) {
      this.users = this.users.map((u) => {
        const clone = { ...u };
        if (clone._id === userId) {
          clone.ban_status = !bool;
        }
        return clone;
      });
    },
    /**
     * filters users only by banned ones and vice versa
     * @param isBanned bool
     * @returns Array
     */
    filterSelectedUsers(isBanned) {
      return this.selected.reduce((filteredUsers, id) => {
        const userIndex = this.users.findIndex((u) => u._id === id);
        if (userIndex > -1) {
          if (isBanned) {
            if (this.users[userIndex].ban_status) {
              filteredUsers.push(this.users[userIndex]._id);
            }
          } else if (!this.users[userIndex].ban_status) {
            filteredUsers.push(this.users[userIndex]._id);
          }
        }
        return filteredUsers;
      }, []);
    },

    changeUserStatus(value, data) {
      // eslint-disable-next-line no-param-reassign
      data.item.status = value;
    },
  },
  computed: {
    allSelectedUsersId() {
      return this.users.map((u) => u._id);
    },
    isBanned() {
      return (data) => !!data.item.ban_status;
    },
    isUserSelected() {
      return (userId) => this.selected.includes(userId);
    },
  },
  watch: {
    selected(newValue) {
      // Handle changes in individual checkboxes
      if (newValue.length === 0) {
        this.allSelected = false;
      } else this.allSelected = newValue.length === this.allSelectedUsersId.length;
    },
  },
};
</script>
