<template>
  <b-dropdown
    size="lg"
    dropdown
    offset="-15"
    variant="link"
    toggle-class="text-decoration-none"
    class="dropdown-status"
    active-class="dropdown-active"
    no-caret
    :disabled="disabled"
  >
    <template #button-content>
      <div class="status-dropdown">
        <div class="status-dropdown__text">
          <p>{{ statusLabel }}</p>
        </div>
        <img
          v-if="!disabled"
          :class="{ active: !visible }"
          src="@/assets/images/svg/arrow-up.svg"
          alt="Arrow"
          @click="visible = !visible"
        />
      </div>
    </template>

    <b-dropdown-item href="#" @click.prevent="setAdmin">{{ $t('label.manager') }}</b-dropdown-item>
    <b-dropdown-item href="#" @click.prevent="setInspector">
      {{ $t('label.inspector') }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: 'StatusDropDown',
  props: {
    status: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    setAdmin() {
      this.$emit('changeStatus', 'admin');
    },
    setInspector() {
      this.$emit('changeStatus', 'inspector');
    },
  },
  computed: {
    statusLabel() {
      if (this.status === 'admin') {
        return this.$t('label.admin');
      }
      return this.$t('label.inspector');
    },
  },
};
</script>
