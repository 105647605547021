<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 1V15"
      stroke="#FAA433"
      stroke-width="2"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
    <path
      d="M1 8H15"
      stroke="#FAA433"
      stroke-width="2"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'PlusIcon',
};
</script>
