<template>
  <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <!-- eslint-disable max-len -->
    <path
      d="M14.8182 16.5453V14.8181C14.8182 13.9019 14.4542 13.0232 13.8064 12.3753C13.1585 11.7275 12.2798 11.3635 11.3636 11.3635H4.45455C3.53834 11.3635 2.65967 11.7275 2.01181 12.3753C1.36396 13.0232 1 13.9019 1 14.8181V16.5453"
      stroke="#F2994A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <!-- eslint-disable max-len -->
    <path
      d="M7.90909 7.90909C9.81698 7.90909 11.3636 6.36244 11.3636 4.45455C11.3636 2.54665 9.81698 1 7.90909 1C6.0012 1 4.45454 2.54665 4.45454 4.45455C4.45454 6.36244 6.0012 7.90909 7.90909 7.90909Z"
      stroke="#F2994A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <!-- eslint-disable max-len -->
    <path
      d="M20 16.5461V14.8188C19.9994 14.0534 19.7447 13.3099 19.2757 12.7049C18.8068 12.1 18.1502 11.6679 17.4091 11.4766"
      stroke="#F2994A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <!-- eslint-disable max-len -->
    <path
      d="M13.9545 1.11304C14.6976 1.3033 15.3563 1.73546 15.8266 2.3414C16.2969 2.94733 16.5522 3.69257 16.5522 4.45963C16.5522 5.22668 16.2969 5.97192 15.8266 6.57786C15.3563 7.1838 14.6976 7.61596 13.9545 7.80622"
      stroke="#F2994A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'AllUsersIcon',
};
</script>
