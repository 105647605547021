<template>
  <div class="btn-toggler">
    <div class="btn-toggler__item" @click="select(true)">
      <p>{{ $t('label.statAndStudents') }}</p>
    </div>
    <div class="btn-toggler__item" @click="select(false)">
      <p>{{ $t('label.admins') }}</p>
    </div>
    <div
      class="btn-toggler__button"
      :class="{ 'btn-toggler__button--position-right': !isLeftPosition }"
    >
      <transition name="fade-150" mode="out-in">
        <p :key="buttonLabel">{{ buttonLabel }}</p>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToggleButton',
  props: {
    isLeftPosition: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    buttonLabel() {
      return this.isLeftPosition ? this.$t('label.statAndStudents') : this.$t('label.admins');
    },
  },
  methods: {
    select(bool) {
      this.$emit('update:isLeftPosition', bool);
    },
  },
};
</script>
